import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "./slices/storeSlice";
import prizeReducer from "./slices/prizes";
import storeSubscriptionReducer from "./slices/store";
import uiReducer from "./slices/ui/slice";
import billing from "./slices/billing";
import authReducer from "./slices/auth";
import countriesReducer from "./slices/countries/slice";
import notifications from "./slices/notifications/slice";
import locations from "./slices/locations/slice";
import coupons from "./slices/coupons/slice";
import permission from "./slices/permission/slice";
import configuration from "./slices/configurations/slice";
import categories from "./slices/categories/slice";

export const store = configureStore({
  reducer: {
    store: storeReducer,
    prize: prizeReducer,
    storeSubscription: storeSubscriptionReducer,
    ui: uiReducer,
    auth: authReducer,
    billing,
    countries: countriesReducer,
    notifications,
    locations,
    coupons,
    permission,
    configuration,
    categories
  },
});
